var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Utils, ExceptionService } from '@busuu/legacy-core';
import GTM from 'tracking/tracking-gtm.js';
import Snowplow from 'tracking/tracking-snowplow.js';
import Floodlight from 'tracking/tracking-floodlight';
import TrackingEventsConstants from 'tracking/tracking-constants';
import CurrentUser from 'user/current-user-service.js';
import QueryParameters from 'helpers/query-parameters.js';
import MobileService from 'mobile/mobile-service';
import { getWebAttribution, getWebPrevAttribution, storeWebAttribution } from '@busuu/marketing';
import { getSnowplowDuid } from '@busuu/tracking';
import { getParameter } from '@busuu/query-params';
import { OneTrustCookieConsentService } from '@busuu/cookie-consent';
import ConfigService from 'config/config-service';
import { initOptimizelyUserInfo, updateOptimizelyUserCountry } from '@busuu/experiments';
// Vars
let currentUserID = null;
const trackingProviders = [
    { name: 'GTM', provider: GTM, logs: false },
    { name: 'Snowplow', provider: Snowplow, logs: false },
    { name: 'Floodlight', provider: Floodlight, logs: false },
];
const filename = 'tracking-controller.ts';
/**
 * Logs an error and optionally to Sentry with type: 'error'
 * @param {string} message
 * @param {object} error
 * @param {string} type
 */
const logError = (message, error, type = 'warning') => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    ExceptionService.handle(type, {
        filename: filename,
        message,
        data: error,
        sendToSentry: !!(type === 'error'),
    });
};
/**
 * We usually always want to store the attribution cookie that stores
 * our UTMs and GCLIDs but there's some special cases where we don't:
 *
 * - When a user tries to access a logged-in page and gets redirected to `/logout` with a `redirect` param (because the UTMs are URL encoded in the redirect value)
 *
 * @returns {boolean}
 */
const shouldSkipStoringAttribution = () => {
    return !!getParameter('redirect');
};
/**
 * Returns an event template from an event name
 * @private
 */
const getEventTemplate = (eventName) => {
    const eventTemplate = TrackingEventsConstants[eventName];
    return eventTemplate;
};
/**
 * Extends the event parameters with common params.
 * @private
 */
const extendWithCommonData = (params) => {
    /** Session/Vistor ID are currently pulled from Snowplow. */
    const { domain_sessionid: sessionId, domain_userid: visitorId } = getSnowplowDuid() || {
        domain_sessionid: '',
        domain_userid: '',
    };
    const attribution = getWebAttribution() || {};
    const prevAttribution = getWebPrevAttribution() || {}; // This is an attribution for debugging https://busuucom.atlassian.net/browse/GA-2313
    if (attribution.error)
        logError('extendWithCommonData() - getWebAttribution() resulted in error.', attribution.error, 'error');
    if (prevAttribution.error)
        logError('extendWithCommonData() - getWebPrevAttribution() resulted in error.', prevAttribution.error, 'error');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, Utils.deepClone(params)), (MobileService.isAuthenticationOrRegistrationMobileFlow() && {
        auth_type: QueryParameters.getMobileAuthParameter(),
    })), Utils.deepClone(QueryParameters.getInstitutionParameters())), { 
        /** Attaches `session_id` */
        session_id: sessionId || '', 
        /** Attaches `visitor_id` */
        visitor_id: visitorId || '', attribution: Object.assign(Object.assign({}, ((window === null || window === void 0 ? void 0 : window.BUSUU_PAGE_NAMING) || {})), (!attribution.error ? attribution : {})) }), (!prevAttribution.error && { prev_attribution: prevAttribution }));
};
/**
 * Checks if we can send the event to the tracking service
 */
const canSendEvent = (eventTemplate, trackingServiceName) => {
    const isExcluded = eventTemplate.exclusions.includes(trackingServiceName);
    return !isExcluded;
};
/**
 * Send an event to all tracking provider
 * @private
 */
const sendEvent = (eventName, data) => {
    /** Always store web cookie (if needed) before sending an event */
    const result = storeWebAttribution({ debug: false, skipStoringCookie: shouldSkipStoringAttribution() });
    if (result && result.error)
        logError('sendEvent() - storeWebAttribution() resulted in error.', result.error, 'error');
    const eventTemplate = getEventTemplate(eventName);
    const params = extendWithCommonData(data);
    trackingProviders.forEach(({ name, provider }) => {
        if (canSendEvent(eventTemplate, name)) {
            provider.sendEvent(eventTemplate, params);
        }
    });
};
/**
 * When user is recognised, we add extra data to trackers.
 * We add a logic to avoid multiple authentications
 * based on the current user id. In some cases this ID can change
 * and a new user needs to be identified. (e.g.: registration)
 * @private
 */
const identifyUser = (user) => {
    if (currentUserID === user.uid) {
        return false;
    }
    // Add Sentry user context
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    ExceptionService.identifyUser(user);
    trackingProviders.forEach(({ provider }) => {
        provider.identifyUser(user);
    });
    initOptimizelyUserInfo(user);
    // Store userID to avoid multiple authentications
    currentUserID = user.uid;
};
// Update user country code in Optimizely
const identifyUserCountryForOptimizely = () => __awaiter(void 0, void 0, void 0, function* () {
    const countryCode = yield ConfigService.getCountryCode();
    updateOptimizelyUserCountry({ countryCode });
});
/**
 * Middleware wrapper before sending events.
 * This function tries to get some information
 * about the user first and then send the event,
 * so we can ensure that we have user information
 * along with the event when it's available.
 * @public
 */
const send = (eventName, data = {}) => {
    const onComplete = () => {
        sendEvent(eventName, data);
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    CurrentUser.get()
        .then(identifyUser)
        .catch(
    // Fall back to user IP for country code if current user service call fails
    // Applies to Optimizely only
    identifyUserCountryForOptimizely)
        .then(onComplete);
};
/**
 * Shortcut handler for send() that wraps a promise
 * that always resolves. It ensures the tracking has been
 * sent using a delay timeout to let enough time
 * for the tracking request to process.
 * @public
 */
const sendAndWait = (eventName, data = {}) => {
    send(eventName, data);
    return new Promise((resolve) => {
        window.setTimeout(resolve, 750);
    });
};
const sendCookieTracking = (trackingEvent, params) => {
    const eventParams = {};
    for (const key in params) {
        if (key === 'platform' || key === 'source_page' || key === 'cookies') {
            eventParams[key] = params[key];
        }
    }
    return send(trackingEvent, eventParams);
};
// Timeout workaround to ensure window.OneTrust is loaded
const initCookieTracking = () => {
    setTimeout(() => {
        OneTrustCookieConsentService.init(sendCookieTracking);
    }, 2000);
};
const init = () => {
    /**
     * When initialising tracking, ensure that the web attribution cookie is stored
     */
    const result = storeWebAttribution({ debug: false, skipStoringCookie: shouldSkipStoringAttribution() });
    if (result && result.error)
        logError('init() - storeWebAttribution() resulted in error.', result.error, 'error');
    trackingProviders.forEach(({ provider: { load }, logs }) => {
        if (load) {
            load({ logs });
        }
    });
};
init();
const TrackingController = {
    send,
    sendAndWait,
    initCookieTracking,
};
export default TrackingController;
